/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800");
/*Boxed layout width*/
/**
 * Table Of Content
 *
 * 	1. Colors
 *	2. Typography
 *	3. Forms
 *	4. Tabs
 */
/*******************
 Gradiant mixin
*******************/
/*******************
User card page
******************/
.el-element-overlay .white-box {
  padding: 0px; }

.el-element-overlay .el-card-item {
  position: relative;
  padding-bottom: 25px; }
  .el-element-overlay .el-card-item .el-card-avatar {
    margin-bottom: 15px; }
  .el-element-overlay .el-card-item .el-card-content {
    text-align: center; }
    .el-element-overlay .el-card-item .el-card-content h3 {
      margin: 0px; }
    .el-element-overlay .el-card-item .el-card-content a {
      color: #eef5f9; }
      .el-element-overlay .el-card-item .el-card-content a:hover {
        color: #5e72e4; }
  .el-element-overlay .el-card-item .el-overlay-1 {
    width: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default; }
    .el-element-overlay .el-card-item .el-overlay-1 img {
      display: block;
      position: relative;
      -webkit-transition: all .4s linear;
      transition: all .4s linear;
      width: 100%;
      height: auto; }
    .el-element-overlay .el-card-item .el-overlay-1:hover img {
      -ms-transform: scale(1.2) translateZ(0);
      -webkit-transform: scale(1.2) translateZ(0);
      /* transform: scale(1.2) translateZ(0); */ }
    .el-element-overlay .el-card-item .el-overlay-1 .el-info {
      text-decoration: none;
      display: inline-block;
      text-transform: uppercase;
      color: #fff;
      background-color: transparent;
      filter: alpha(opacity=0);
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
      padding: 0;
      margin: auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%) translateZ(0);
      -webkit-transform: translateY(-50%) translateZ(0);
      -ms-transform: translateY(-50%) translateZ(0); }
      .el-element-overlay .el-card-item .el-overlay-1 .el-info > li {
        list-style: none;
        display: inline-block;
        margin: 0 3px; }
        .el-element-overlay .el-card-item .el-overlay-1 .el-info > li a {
          color: #fff;
          background: transparent;
          border: 1px solid rgba(120, 130, 140, 0.13); }
          .el-element-overlay .el-card-item .el-overlay-1 .el-info > li a:hover {
            background: #5e72e4;
            border-color: #5e72e4; }
  .el-element-overlay .el-card-item .el-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out; }
  .el-element-overlay .el-card-item .el-overlay-1:hover .el-overlay {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0); }
  .el-element-overlay .el-card-item .el-overlay-1 .scrl-dwn {
    top: -100%; }
  .el-element-overlay .el-card-item .el-overlay-1 .scrl-up {
    top: 100%;
    height: 0px; }
  .el-element-overlay .el-card-item .el-overlay-1:hover .scrl-dwn {
    top: 0px; }
  .el-element-overlay .el-card-item .el-overlay-1:hover .scrl-up {
    top: 0px;
    height: 100%; }
