@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800");
/*Boxed layout width*/
/**
 * Table Of Content
 *
 * 	1. Colors
 *	2. Typography
 *	3. Forms
 *	4. Tabs
 */
/*******************
 Gradiant mixin
*******************/
.auth-wrapper {
  min-height: 100vh;
  position: relative; }
  .auth-wrapper .auth-box {
    background: #fff;
    padding: 20px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.01), 0 3px 1px -2px rgba(0, 0, 0, 0.02), 0 1px 5px 0 rgba(0, 0, 0, 0.02);
    max-width: 400px;
    width: 90%;
    margin: 10% 0; }
    .auth-wrapper .auth-box .logo {
      text-align: center; }
  .auth-wrapper #recoverform {
    display: none; }
  .auth-wrapper .auth-sidebar {
    position: fixed;
    height: 100%;
    right: 0px;
    overflow: auto;
    margin: 0px;
    top: 0px; }

@media (max-width: 767px) {
  .auth-wrapper .auth-sidebar {
    position: relative;
    max-width: 100%;
    width: 100%;
    margin: 40px 0 60px; }
  .auth-wrapper .demo-text {
    margin-top: 30px; } }
